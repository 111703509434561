<!--
 * @Descripttion: 产品
 * @version: 
 * @Author: alex.yang
 * @Date: 2024-05-11 09:42:46
 * @LastEditors: alex.yang
 * @LastEditTime: 2024-08-01 11:31:46
-->
<template>
    <div class="webProduct">
        <div class="d-w-title flex-sbc">
            <div class="wt-title flex-wc" @click="clickGoBalck">
                <div class="balck">
                    <i class="el-icon-arrow-left"></i>
                </div>
                <div class="title">服务</div>
            </div>
        </div>
        <!-- <div class="wp-title-wrap">
            <img class="logo" src="https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240511/4a38157246c66c15f0ad3870206ff7ec.png" alt="">
            <div class="title">专为个体小微企业打造的AI云进销存软件</div>
            <div class="tip">移动端&PC端数据实时同步</div>
            <div class="btn flex-cc" @click="clickRegisterTry">注册试用</div>
        </div> -->
        <!-- <div class="wp-do-wrap">
            <div class="dw-title">有库云能为您做什么？</div>
            <div class="dw-content flex-sbc">
                <div class="dc-item flex-coc">
                    <img class="icon" src="https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240511/7ea0b79a58faebe687ea3e40fdac1c35.png" alt="">
                    <div class="title">规范销售流程，提升销售效率</div>
                    <div class="content">通过自动化销售流程，减少人工干预避免错误和延误，实现快速响应客户求。</div>
                </div>
                <div class="dc-item flex-coc">
                    <img class="icon" src="https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240511/817561da2d68343dff3527e3e3ce0d6a.png" alt="">
                    <div class="title">精细库存管理，优化库存成本</div>
                    <div class="content">利用先进的库存管理算法，实时监控库存水平，预测需求，减少过剩或缺货风险，有效降低库存成本。</div>
                </div>
                <div class="dc-item flex-coc">
                    <img class="icon" src="https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240511/b305afcb6abe7e9c55a33093873d4bf9.png" alt="">
                    <div class="title">全面清晰记账，避免账目混乱</div>
                    <div class="content">提供详尽的记账功能，确保每一笔交易都有迹可循，账目清晰，便于审计和财务分析，避免财务混乱。</div>
                </div>
                <div class="dc-item flex-coc">
                    <img class="icon" src="https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240511/2828dafa84ad2fcbbadd069c52d553c5.png" alt="">
                    <div class="title">实时生成报表，及时发现问题</div>
                    <div class="content">系统能够实时生成各种财务和业务报表，帮助管理者快速把握经营状况，及时发现并解决潜在问题。</div>
                </div>
            </div>
        </div> -->
        <!-- <div class="wp-do-wrap">
            <div class="dw-title">智能革新，让卓越管理触手可及</div>
            <div class="dw-box">
                <div class="db-item">
                    <div class="title">多端互联，云端数据同步，高效协同管理。</div>
                    <div class="content">有库云作为云进销存软件，可实现数据在多端实时同步，可利用微信小程序登录有库云移动端，也可通过电脑端应用支持Windows系统、MacOS系统以及网页端进行登录操作，并且支持大量的数据存储及备份。让经营者及员工不受设备限制，高效地进行业务管理。</div>
                    <img class="icon" src="https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240511/f0fe3a20b585f3592785c2788ed3266b.png" alt="">
                </div>
                <div class="db-item">
                    <div class="title">订单自动化，实现高效简洁的流程。</div>
                    <div class="content">实现订单处理自动化，包括订单处理出库和入库及记账，减少手动操作，提高订单处理速度。</div>
                    <img class="icon" src="https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240511/04cd0c22479b1049ef6a4266f63827f9.png" alt="">
                </div>
                <div class="db-item">
                    <div class="title">业务智能和数据分析，让数据驱动决策。</div>
                    <div class="content">提供强大的数据分析工具，帮助用户从销售和库存数据中获得洞察，支持数据驱动的决策。</div>
                    <img class="icon" src="https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240511/3cc3c0ef48e26c4a5cd2ff684aaf73ea.png" alt="">
                </div>
            </div>
        </div> -->
        <div class="wp-do-wrap">
            <div class="dw-title">安全、先进、上手无忧的保障服务</div>
            <div class="dw-box">
                <div class="db-item">
                    <div class="title">让数据安全成为您持续发展的有力保障。</div>
                    <div class="content">您的数据存储在我们的云端服务器，会进行24/7持续安全监控。并且我们采用了高标准的加密技术来保护数据的安全性 。云服务遵循如ISO 27001、GDPR等行业标准和安全认证确保数据安全和隐私的合规性。</div>
                    <img class="icon" src="https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240511/4c433dc103e8de3a18aaffebb573c69a.gif" alt="">
                </div>
                <div class="db-item" style="background: #fff;">
                    <div class="title">始终创新技术，让其在业务场景中发挥更多可能性。</div>
                    <div class="content">在您使用有库云PRO版时，基于您使用现有功能的优化，以及可能使用过程您会提出的一些建设性需求，我们会积极跟进了解，尽可能完善您的建议，同时不收取费用。与此同时，我们也始终关注前沿技术的发展，希望通过与业务融合，进行创新升级，让其您的生意中发挥更大的作用。</div>
                    <img class="icon" src="https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240511/00c1130be681773bc58ce201bc664679.png" alt="">
                </div>
                <div class="db-item" style="background: #fff;">
                    <div class="title">我们关注简洁、高效的使用体验，配套一对一培训让系统易上手。</div>
                    <div class="content">我们尽可能奉行极简主义设计功能及页面，让用户有轻松易上手，在培训上让用户降低学习成本同时，在您准备好产品资料、库存资料等信息后，我们将辅助您尽可能在短的时间投入经营使用。</div>
                    <img class="icon" src="https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240511/f4b550afbdcf51a7cf08141878d210f4.jpg" alt="">
                </div>
            </div>
        </div>
        <!-- <div class="wp-tab-wrap">
            <div class="tw-title">
                <div class="title">获取适合的有库云PRO版本</div>
                <div class="tip">支持低版本升级为高版本</div>
            </div>
            <div class="tw-tab">
                <div class="tw-t-title flex-sbc">
                    <div class="tab" :class="tabIndex == index?'active':''"
                        v-for="(item,index) in tabList" :key="index"
                        @click="clickTab(item,index)">
                        {{item.title}}
                    </div>
                </div>
                <div class="tw-t-content flex-coc">
                    <div class="tc-num">{{info.num}}</div>
                    <div class="tc-price" v-if="info.price == '免费'"><span>{{info.price}}</span></div>
                    <div class="tc-price" v-else>￥<span>{{info.price}}</span>/年</div>
                    <div @click="clickOpen" class="tc-btn flex-cc">立即开通PRO版</div>
                    <img class="tc-img" :src="info.image" alt="">
                </div>
            </div>
        </div> -->
        <!-- <div class="wp-user-wrap">
            <div class="uw-title">成为有库云10000+用户之一</div>
            <div class="uw-content flex-sb">
                <img class="c-head" src="https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240511/795dc4d65a924d32d42d4f73453673fa.jpg" alt="">
                <div class="c-info">
                    <div class="ci-title flex-wc">
                        <div class="title">吴先生，家居建材经营者</div>
                        <img class="icon" src="https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240511/7c6ca992a10d0e05c5b7d66b9c4ca9bc.png" alt="">
                    </div>
                    <div class="ci-content">我们经营建材生意很多年了，之前也一直没有想过用什么软件。因为确实这方便一点都没接触过，但接触到有库云的小徐聊得过程中让我感觉到有种紧迫性应该提升我们的经营效率！原来我们仓库经常会有小色号的产品，堆积一段时间了就会便宜处理掉。这一年多的时间我们通过有库云精细化了库存管理，开单派货时合理安排色号，基本解决了这个问题。在开单查货时也比以前更方便了。目前主要我女儿在用有库云，还是给她省了不少心，也可以腾出时间多去分析数据，改善经营。</div>
                </div>
            </div>
        </div> -->
        <div class="wp-question-wrap">
            <div class="qw-title">常见问题   Q&A</div>
            <div class="qu-content">
                <el-collapse v-model="activeName" accordion>
                    <el-collapse-item :name="index"
                        v-for="(item,index) in question" :key="index">
                        <template slot="title">
                            <div class="title">{{item.title}}</div>
                        </template>
                        <div class="content">{{item.content}}</div>
                    </el-collapse-item>
                </el-collapse>
            </div>
        </div>
        <div class="wp-tip-wrap">东有创新 © 2024 Ukoocloud  粤ICP备2023127805号-1 </div>
        <div style="height: 80px"></div>
        <div class="wp-btn-wrap flex-sbc">
            <div @click="clickOpen" class="btn open flex-cc">免费开通</div>
            <div @click="clickDemo" class="btn flex-cc">免费预约演示</div>
        </div>
    </div>
</template>

<script>
export default {
    data () {
        return {
            tabList: [{
                title: '基础版',
                price: '免费',
                num: '共计1个账号空间',
                image: 'https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240511/5900199226d23467cb9178245983ecb6.png'
            },{
                title: '3人团队版',
                price: '398',
                num: '共计4个账号空间',
                image: 'https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240511/5900199226d23467cb9178245983ecb6.png'
            },{
                title: '10人团队版',
                price: '998',
                num: '共计11个账号空间',
                image: 'https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240511/5900199226d23467cb9178245983ecb6.png'
            },{
                title: '无限账号版',
                price: '1598',
                num: '不限账号空间 ',
                image: 'https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240511/5900199226d23467cb9178245983ecb6.png'
            }],
            tabIndex: 0,
            info: {},
            activeName: '',
            question: [{
                isShow: false,
                title: '软件系统是否可以不同设备间实现数据实时同步？',
                content: '有库云作为云进销存系统，数据存储在云端。只要设备有接入网络，不同设备即可实现数据实时同步。'
            },{
                isShow: false,
                title: '安装是否会占用手机或电脑设备大量内存？',
                content: '云服务的好处就是，基本数据运行、存储都通过云服务器来完成，并不占用太多本地存储空间，且始终运行流畅。'
            },{
                isShow: false,
                title: '版本账号空间数不够用，能否进行账号空间升级？',
                content: '可以的，账号空间可以选择匹配的有库云版本套餐进行升级，但需要注意的是版本无法降级。'
            },{
                isShow: false,
                title: '软件功能担心团队成员吃不透，用不起来这么办？',
                content: '有库云进销存非常简单、易上手，基本团队成员所要掌握的操作流程都极其简单，遵循本身的业务逻辑，同时我们也会认真耐心的进行培训讲解。但不论如何，最关键也是需要经营者的重视，引导鼓励大家改变，养成习惯。'
            },{
                isShow: false,
                title: '有库云能否在苹果电脑MAC上运行？',
                content: '有库云应用端有网页版、小程序版、Windows版，在苹果电脑上登录网页版即可兼容使用。'
            },{
                title: '使用有库云是否需要额外购买打印设备？',
                content: '通过电脑端连接打印的，无需另行购买打印设备，不论是针式打印机或喷墨打印机有库云都支持。若业务需要支持手机端WIFI远程无线打印的，则需要根据我们提供的配置另行购买。'
            },{
                isShow: false,
                title: '是否支持多仓库、多门店管理？',
                content: '有库云所有版本均支持多仓库、多门店的管理，不额外收取费用。'
            },{
                isShow: false,
                title: '打印销售单、出库单以及购销合同时能否自定义内容？',
                content: '销售单 、出库单备注信息以及收款二维码可以根据自身需求进行自定义。购销合同除商品信息之外的内容可根据合作细节进行自定义。'
            },{
                isShow: false,
                title: '开单时是否有不同客户历史价格记录？',
                content: '同商品不同时期采购价格不同，我们会根据采购价格波动实时计算商品加权平均成本，计算库存金额，确保准确性。'
            },{
                isShow: false,
                title: '我们有些员工负责多个岗位的内容，是否需要多个账号？',
                content: '不用，只需把该员工添加到有库云系统中，然后根据需要进行权限的分配即可。'
            },]
        }
    },
    mounted () {
        this.info = this.tabList[0]
    },
    methods: {
        clickTab(item,index){
            this.tabIndex = index;
            this.info = item
        },
        // 预约演示
        clickDemo(){
            this.$router.push("./webDemonstration")
        },
        // 立即开通
        clickOpen(){
            this.$router.push("./webOpnePro")
        },
        // 注册试用
        clickRegisterTry(){
            this.$router.push("./webRegisterTry")
        },
        clickGoBalck(){
            this.$router.go(-1) 
        },
    },
}
</script>

<style lang='scss'>
.webProduct{
    .d-w-title{
        height: 50px;
        background: #fff;
        padding: 0 20px;
        position: sticky;
        top: 0;
        left: 0;
        right: 0;
        z-index: 9;
        .wt-title{
            width: 100%;
            .balck{
                .el-icon-arrow-left{
                    font-size: 18px;
                    color: #101010;
                    font-weight: bold;
                }
            }
            .title{
                width: calc(100% - 50px);
                font-weight: bold;
                font-size: 16px;
                color: #101010;
                text-align: center;
            }
        }
        .wt-logo{
            .logo{
                width: 70px;
                height: 15px;
            }
        }
    }
    // .wp-title-wrap{
    //     height: 480px;
    //     background-image: url('../../assets/cp_background.png');
    //     background-size: 100% 100%;
    //     padding: 0px 20px;
    //     .logo{
    //         width: 125px;
    //         height: 35px;
    //         margin-top: 40px;
    //     }
    //     .title{
    //         font-size: 28px;
    //         color: #101010;
    //         font-weight: bold;
    //         padding: 15px 0;
    //     }
    //     .tip{
    //         font-size: 14px;
    //         color: #000;
    //     }
    //     .btn{
    //         width: 100px;
    //         height: 36px;
    //         border-radius: 4px;
    //         background: #3e6ae1;
    //         color: #fff;
    //         font-size: 14px;
    //         margin-top: 20px;
    //     }
    // }
    .wp-do-wrap{
        background: #f3f8ff;
        padding: 30px 0px 0 0px;
        .dw-title{
            font-size: 20px;
            color: #101010;
            text-align: center;
            font-weight: bold;
        }
        .dw-content{
            flex-wrap: wrap;
            padding: 40px 20px 0 20px;
            .dc-item{
                width: calc((100% - 30px)/2);
                height: 230px;
                .icon{
                    width: 36px;
                    height: 36px;
                }
                .title{
                    font-size: 13px;
                    color: #101010;
                    font-weight: bold;
                    padding-top: 20px;
                    padding-bottom: 15px;
                }
                .content{
                    font-size: 14px;
                    color: #808080;
                    line-height: 22px;
                    text-align: justify;
                }
            }
        }
        .dw-box{
            .db-item{
                .title{
                    font-size: 14px;
                    color: #000;
                    font-weight: bold;
                    padding: 30px 20px 0 20px;
                }
                .content{
                    font-size: 12px;
                    color: #808080;
                    line-height: 22px;
                    text-align: justify;
                    padding: 15px 20px;
                }
                .icon{
                    vertical-align: bottom;
                    width: 100%;
                }
            }
        }
    }
    .wp-tab-wrap{
        background: #eff5fd;
        padding: 30px 20px;
        margin-top: 30px;
        .tw-title{
            .title{
                font-size: 20px;
                color: #101010;
                font-weight: bold;
                text-align: center;
            }
            .tip{
                font-size: 12px;
                color: #c4c4c4;
                text-align: center;
                padding: 10px 0;
            }
        }
        .tw-tab{
            background: #fff;
            border-radius: 8px;
            margin-top: 30px;
            padding: 10px 20px;
            .tw-t-title{
                border-bottom: 1px solid #d7d7d7;
                .tab{
                    font-size: 14px;
                    color: #808080;
                    padding: 15px 0;
                    border-bottom: 1px solid rgba(0, 0, 0, 0);
                }
                .active{
                    color: #0068ff;
                    font-weight: bold;
                    border-bottom-color: #0068ff;
                }
            }
        }
        .tw-t-content{
            .tc-num{
                font-size: 14px;
                color: #7B7B7B;
                padding: 25px;
            }
            .tc-price{
                font-size: 12px;
                color: #04963a;
                span{
                    font-size: 24px;
                    font-weight: bold;
                }
            }
            .tc-btn{
                width: 100%;
                height: 46px;
                font-size: 14px;
                color: #fff;
                background: #3e6ae1;
                border-radius: 4px;
                margin: 25px 0;
            }
            .tc-img{
                width: 100%;
            }
        }
    }
    .wp-user-wrap{
        padding: 30px 0;
        .uw-title{
            font-size: 20px;
            color: #101010;
            font-weight: bold;
            text-align: center;
        }
        .uw-content{
            margin: 30px 0;
            background: #eff5fd;
            padding: 30px 20px;
            .c-head{
                width: 100px;
                height: 100px;
                border-radius: 4px;
                overflow: hidden;
            }
            .c-info{
                width: calc(100% - 115px);
                .ci-title{
                    .title{
                        font-size: 14px;
                        color: #101010;
                        font-weight: bold;
                    }
                    .icon{
                        height: 15px;
                        width: 80px;
                        margin-left: 10px;
                    }
                }
                .ci-content{
                    font-size: 14px;
                    color: #000;
                    line-height: 24px;
                    padding-top: 10px;
                    text-align: justify;
                }
            }
        }
    }
    .wp-question-wrap{
        padding-top: 60px;
        .qw-title{
            font-size: 20px;
            color: #101010;
            font-weight: bold;
            text-align: center;
        }
        .qu-content{
            padding: 30px 20px;
            .title{
                line-height: 22px;
                font-size: 14px;
                color: #101010;
            }
            .content{
                color: #808080;
            }
        }
    }
    .wp-btn-wrap{
        padding: 15px 20px;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        background: #fff;
        z-index: 999;
        box-shadow: 0px 0px 10px 4px rgba(100, 100, 100, 0.1);
        .btn{
            width: calc((100% - 30px)/2);
            font-size: 14px;
            color: #101010;
            background: #F2F2F2;
            height: 46px;
            border-radius: 4px;
        }
        .open{
            background: #0068ff;
            color: #fff;
        }
    }
    .wp-tip-wrap{
        font-size: 12px;
        color: #999;
        text-align: center;
        padding: 15px 0;
        background: #000;
    }
}
</style>
